<template>
  <div class="modal-wrap-card">
    <div class="condition">
      <AdvancedInput
        v-for="(item, index) in advanceds"
        :key="item+index"
        :advancedKey="index"
        :advanced="item"
        :len="advanceds.length - 1"
        @minus="isMinus"
        @plus="isPlus"
      />
    </div>
    <div class="history">
      <div class="title">检索历史</div>
      <template v-if="lists.length">
        <div class="history-lists">
          <div class="history-item"
            v-for="(item, index) in lists"
            :key="item+index"
          >
            <div class="item">
              <div class="time">{{item.date}} <span>{{item.time}}</span></div>
              <div class="labels" @click="handleItem(item)">
                <div class="label"
                  v-for="(label, index) in item.labels"
                  :key="index"
                  :class="isLogical(label) ? 'logic': 'normal'"
                >
                {{isLogical(label) ? label.toLowerCase() : label}}
                </div>
              </div>
              <!-- <div class="subscribe">+ 订阅</div> -->
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="none-history">
          暂无检索历史
        </div>
      </template>
    </div>
    <div class="footer">
      <Button type="text" @click="isCancel">取消</Button>
      <Button class="sure" type="primary" @click="isSure">确定</Button>
    </div>
  </div>
</template>

<script>
import { Button, Message } from 'view-design'
import AdvancedInput from '../AdvancedInput'
export default {
  name: 'ModalCard',
  data () {
    return {
    }
  },

  props: {
    lists: {
      type: Array,
      default: () => []
    },
    advanceds: {
      type: Array,
      default: () => []
    }
  },

  components: {
    Button,
    AdvancedInput
  },

  computed: {
    isLogical() {
      return function (label) {
        return label.toLowerCase() === 'or' || label.toLowerCase() === 'and'
      }
    }
  },

  methods: {
    handleItem(item) {
      this.$emit('history', item)
    },
    isCancel() {
      this.$emit('cancel')
    },
    isSure() {
      this.$emit('sure')
    },
    isMinus(key) {
      this.$emit('minus', key)
    },
    isPlus() {
      const len = this.advanceds.length
      if (len <= 9) {
        this.$emit('plus')
      } else {
        Message.warning({
          content: '最多添加10条',
          duration: 2
        })
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>