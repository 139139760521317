<template>
  <div class="field-card" @mouseenter="isMouseEnter" @mouseleave="isMouseLeave">
    <template v-if="!field.isHover">
      <div class="field-classify" @click="isSingleFieldClick">
        <div class="field-img">
          <img :src="field.unhover">
        </div>
        <div class="field-desc">
          <div class="field">{{field.field}}</div>
          <div class="field-nums">
            <div class="desc">
              <div class="num">{{field.total}}</div>
              <div class="word">文献总计</div>
            </div>
            <div class="desc">
              <div class="num">{{field.updates}}</div>
              <div class="word">7日更新</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="hover-card" :style="{backgroundImage: `url(${field.hover})`}">
        <div class="classify-title">{{field.field}}</div>
        <div class="variety">
          <div class="disease-item"
          v-for="(item, index) in field.classify"
          :key="index"
          @click="handleVarietyItem(item)"
          >
            {{item.label}} >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { debounce } from 'utils/tools'
import { Message } from 'view-design'
export default {
  name: 'FieldCard',

  props: {
    field: {
      type: Object,
      default: () => {}
    },
    filedKey: {
      type: Number,
      default: 0
    }
  },

  computed: {
    isClassify() {
      return this.field.classify.length
    }
  },

  methods: {
    isMouseToggle(type) {
      this.isClassify && this.$emit(type, this.filedKey)
    },
    isMouseEnter() {
      debounce(this.isMouseToggle('enter'), 1000)
    },
    isMouseLeave() {
      debounce(this.isMouseToggle('leave'), 1000)
    },
    handleVarietyItem(item) {
      const { field } = this.field
      const { count } = item
      if (count && count !== 0) {
        this.$emit('variety', item, field)
      } else {
        Message.warning({
          content: '暂未开放，敬请期待！',
          duration: 2
        })
      }
    },
    isSingleFieldClick() {
      const { total } = this.field
      if (total && total !== 0) {
        this.$emit('single', this.field)
      } else {
        Message.warning({
          content: '暂未开放，敬请期待！',
          duration: 2
        })
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>