<template>
  <div class="formula-lists">
    <div class="formula">
      <div class="left">
        <div class="time">{{formula.date}} <div class="hours">{{formula.time}}</div></div>
        <div class="labels" @click="handleFormula">
          <div class="label"
            v-for="(label, index) in formula.labels"
            :key="index"
            :class="isLogical(label) ? 'logic': 'normal'"
          >
          {{isLogical(label) ? label.toLowerCase() : label}}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="literature-nums">
          <div class="updates">7日更新：<span>{{formula.updates}}</span></div>
          <div class="total">总计：<span>{{formula.total}}</span></div>
        </div>
        <div class="delete" @click="isDelete">
          <i class="iconfont iconshanchu"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectCard',

  props: {
    formula: {
      type: Object,
      default: () => {}
    },
    formulaKey: {
      type: Number,
      default: 0
    }
  },

  computed: {
    isLogical() {
      return function (label) {
        return label.toLowerCase() === 'or' || label.toLowerCase() === 'and'
      }
    }
  },

  methods: {
    handleFormula() {
      this.$emit('formula', this.formula)
    },
    isDelete() {
      const { id } = this.formula
      this.$emit('delete', id)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>