<template>
  <div class="search-card-wrap" :class="{'islists': carry}">
    <Input
      v-model="value"
      placeholder="输入关键词查询"
      size="large"
      @on-enter="isSearch"
    />
    <div class="search-button">
      <Button
        size="large"
        type="primary"
        ghost
        @click="isSearch"
      >
        搜索
      </Button>
    </div>
    <template v-if="!library">
      <div v-if="!carry" class="advanced" @click="isAdvanceClick">高级搜索</div>
    </template>
  </div>
</template>

<script>
import { Input, Button, Message } from 'view-design'
import { limitInputValue } from '@/utils/tools'
export default {
  name: 'SearchCard',
  data () {
    return {
      value: ''
    }
  },

  props: {
    carry: {
      type: String,
      default: ''
    },
    library: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Input,
    Button
  },

  watch: {
    carry: {
      handler(val) {
        this.value = val
      },
      immediate: true
    }

  },

  methods: {
    isSearch() {
      const val = this.value.trim()
      const len = limitInputValue(val)
      if (len > 200) {
        Message.warning({
          content: '输入最大长度为200字符长度！',
          duration: 2
        })
      } else {
        console.log(val, 'comss')
        val && this.$emit('search', val)
      }
    },
    isAdvanceClick() {
      this.$emit('advance')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>