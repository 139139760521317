<template>
  <div class="advanced-search-wrap">
    <template v-if="advancedKey === 0">
      <Select
        v-model="advanced.label"
        style="width:300px"
        placeholder="请选择"
        :size="size"
      >
        <Option
          v-for="item in labelsLists"
          :value="item.value"
          :key="item.value"
        >
          {{ item.label }}
        </Option>
      </Select>
    </template>
    <template v-else>
      <Select
        v-model="advanced.logic"
        style="width:100px"
        placeholder="请选择"
        :size="size"
      >
        <Option
          v-for="item in logicLists"
          :value="item.value"
          :key="item.value"
        >
          {{ item.label }}
        </Option>
      </Select>
      <Select
        v-model="advanced.label"
        style="width:190px;margin-left:10px"
        placeholder="请选择"
        :size="size"
      >
        <Option
          v-for="item in labelsLists"
          :value="item.value"
          :key="item.value"
        >
          {{ item.label }}
        </Option>
      </Select>
    </template>
    <div class="search-input">
      <Input
        v-model="advanced.search"
        placeholder="请输入"
        :style="{'width': size === 'large' ? '55vw' : '560px'}"
        :size="size"
      />
    </div>
    <div class="icon-area">
      <template v-if="minus">
        <i class="iconfont iconjian" @click="isMinus"/>
      </template>
      <template v-if="isLast">
        <i class="iconfont iconjia" @click="isPlus"/>
      </template>
    </div>
  </div>
</template>

<script>
import { labelsLists, logicLists } from '../../config'
import { Select, Option, Input, Message } from 'view-design'
import { limitInputValue } from '@/utils/tools'
export default {
  name: 'AdvancedInput',
  data () {
    return {
      labelsLists,
      logicLists
    }
  },

  props: {
    advanced: {
      type: Object,
      default: () => {}
    },
    advancedKey: {
      type: Number,
      default: 0
    },
    len: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'default'
    }
  },

  components: {
    Select,
    Option,
    Input
  },

  computed: {
    isLast() {
      // 第一个
      if (this.len === 0 && this.advancedKey === 0) {
        return true
      } else {
        return this.advancedKey === this.len
      }
    },
    minus() {
      return !(this.len === 0 && this.advancedKey === 0)
    }
  },

  methods: {
    isMinus() {
      this.$emit('minus', this.advancedKey)
    },
    isPlus() {
      const val = this.advanced.search.trim()
      const len = limitInputValue(val)
      if (this.advancedKey > 0 && !this.advanced.logic) {
        Message.warning({
          content: '请选择对应的条件！',
          duration: 2
        })
      } else if (!val || !this.advanced.label) {
        Message.warning({
          content: '请输入对应内容后再添加！',
          duration: 2
        })
      } else if (len > 200) {
        Message.warning({
          content: '输入最大长度为400字符长度！',
          duration: 2
        })
      } else {
        this.$emit('plus')
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>