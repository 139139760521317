// 肿瘤 lung
const lung = require('../../assets/imgs/field/lung.png')
const lungEnter = require('../../assets/imgs/field/lung-enter.png')

// 感染 infection
const infection = require('../../assets/imgs/field/infection.png')
const infectionEnter = require('../../assets/imgs/field/infection-enter.png')

// 心血管 angiocarpy
const angiocarpy = require('../../assets/imgs/field/angiocarpy.png')
const angiocarpyEnter = require('../../assets/imgs/field/angiocarpy-enter.png')

// 血液 blood
const blood = require('../../assets/imgs/field/blood.png')
const bloodEnter = require('../../assets/imgs/field/blood-enter.png')

const normal = require('../../assets/imgs/field/normal.png')
const normalEnter = require('../../assets/imgs/field/normal-enter.png')

// genitals
const genitals = require('../../assets/imgs/field/genital.png')
const genitalsEnter = require('../../assets/imgs/field/genital-enter.png')

// gestation
const genstation = require('../../assets/imgs/field/genstation.png')
const genstationEnter = require('../../assets/imgs/field/genstation-enter.png')

const diabetes = require('../../assets/imgs/field/diabetes.png')
const diabetesEnter = require('../../assets/imgs/field/diabetes-enter.png')

// 领域相关
export const fieldLists = [
  {
    field: '肿瘤',
    unhover: lung,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: lungEnter
  },
  {
    field: '感染',
    unhover: infection,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: infectionEnter
  },
  {
    field: '心脑血管疾病',
    unhover: angiocarpy,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: angiocarpyEnter
  },
  {
    field: '血液疾病',
    unhover: blood,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: bloodEnter
  },
  {
    field: '普通内分泌',
    unhover: normal,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: normalEnter
  },
  {
    field: '生殖内分泌',
    unhover: genitals,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: genitalsEnter
  },
  {
    field: '妊娠合并症',
    unhover: genstation,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: genstationEnter
  },
  {
    field: '糖尿病',
    unhover: diabetes,
    isHover: false,
    total: 1000,
    updates: 100,
    classify: [],
    hover: diabetesEnter
  }
]

export const fieldType = {
  肿瘤: {
    unhover: lung,
    hover: lungEnter
  },
  感染: {
    unhover: infection,
    hover: infectionEnter
  },
  心脑血管疾病: {
    unhover: angiocarpy,
    hover: angiocarpyEnter
  },
  血液系统功能紊乱: {
    unhover: blood,
    hover: bloodEnter
  },
  普通内分泌: {
    unhover: normal,
    hover: normalEnter
  },
  生殖内分泌: {
    unhover: genitals,
    hover: genitalsEnter
  },
  妊娠合并症: {
    unhover: genstation,
    hover: genstationEnter
  },
  糖尿病: {
    unhover: diabetes,
    hover: diabetesEnter
  }
}

// 搜索式
export const searchFormulas = [
  {
    date: '2020-12-11',
    time: '10:24:53',
    updates: 1000,
    total: 1000,
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-12',
    time: '10:24:53',
    updates: 1000,
    total: 1000,
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-13',
    time: '10:24:53',
    updates: 1000,
    total: 1000,
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-14',
    time: '10:24:53',
    updates: 1000,
    total: 1000,
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-15',
    time: '10:24:53',
    updates: 1000,
    total: 1000,
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  }
]

// 搜索历史
export const historyLists = [
  {
    date: '2020-12-11',
    time: '10:24:53',
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-11',
    time: '10:24:53',
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-11',
    time: '10:24:53',
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-11',
    time: '10:24:53',
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  },
  {
    date: '2020-12-11',
    time: '10:24:53',
    labels: ['标签1', 'or', '便签2', 'or', '便签3', 'and', '标签3']
  }
]

// 高级检索下拉
export const labelsLists = [
  {
    label: 'Title',
    value: 'Title'
  },
  {
    label: 'Keywords',
    value: 'Keywords'
  },
  {
    label: 'Abstract/summary',
    value: 'Abstract/summary'
  },
  {
    label: 'DOI',
    value: 'DOI'
  },
  {
    label: 'PMID',
    value: 'PMID'
  },
  {
    label: 'Journal',
    value: 'Journal'
  },
  {
    label: 'First Author',
    value: 'First Author'
  },
  {
    label: 'Author',
    value: 'Author'
  }
]

export const logicLists = [
  {
    label: 'AND',
    value: 'AND'
  },
  {
    label: 'OR',
    value: 'OR'
  }
]

// 高级检索lists
export const AdvancedDefault = [
  {
    label: '',
    search: ''
  }
]