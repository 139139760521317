<template>
  <div class="literature-wrap">
    <div class="disease-field">
      <div class="title">疾病领域 <span>（仅纳入生物标志物相关文献）</span></div>
      <div style="background-color: #fff">
        <skeleton
          v-if="loading"
          type='listcom'
          active
          :options="skeletonOption"
        />
      </div>
      <div class="field-card-wrap">
        <FieldCard
          v-for="(item, index) in fieldLists"
          :key="item+index"
          :field="item"
          :filedKey="index"
          @enter="isMouseOn"
          @leave="isMouseOut"
          @single="isSingleClick"
          @variety="isVarietyClick"
        />
      </div>
    </div>
    <div class="search-area">
      <SearchCard
        @search="isSearch"
        @advance="isAdvance"
      />
    </div>
    <div class="search-wrap">
      <div class="title">检索</div>
      <div class="formulas-card-wrap">
        <div class="subtitle">已收藏的检索式</div>
        <template v-if="searchFormulas.length">
          <div class="collect-formulas-wrap">
            <CollectCard
              v-for="(item, index) in searchFormulas"
              :key="item+index"
              :formulaKey="index"
              :formula="item"
              @formula="isFormula"
              @delete="isDelete"
            />
          </div>
        </template>
        <template v-else>
          <div class="none-formula">暂无收藏的检索式</div>
        </template>
      </div>
    </div>
    <Modal
      v-model="isModalShow"
      width="1016"
      title="高级检索"
      class-name="vertical-center-modal"
      :mask-closable="false"
    >
      <ModalCard
        :advanceds="advancedLists"
        :lists="historyLists"
        @cancel="isCancel"
        @sure="isSure"
        @minus="isMinus"
        @plus="isPlus"
        @history="isHistory"
      />
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import {
  fetchFieldClassify,
  fetchOwnerSearch,
  fetchAdvancedSearch,
  deleteFormulas
} from '@/api'
import {
  AdvancedDefault,
  fieldType
} from './config'
import FieldCard from './components/FieldCard'
import CollectCard from './components/CollectCard'
import SearchCard from './components/SearchCard'
import ModalCard from './components/ModalCard'
import { debounce, setStrToAdvanced } from 'utils/tools'
import { Message, Modal } from 'view-design'
import cloneDeep from 'lodash/cloneDeep'
import { mapMutations } from 'vuex'
export default {
  name: 'Literature',
  data () {
    return {
      fieldLists: [],
      searchFormulas: [],
      historyLists: [],
      isModalShow: false,
      advancedLists: cloneDeep(AdvancedDefault),
      loading: true,
      skeletonOption: {
        row: 8,
        lineHight: 20
      }
    }
  },

  components: {
    FieldCard,
    CollectCard,
    SearchCard,
    ModalCard,
    Modal
  },

  created() {
    this.fieldClassify()
    this.OwnerSearch()
  },

  methods: {
    ...mapMutations('literature', ['setFlag']),
    // 获取领域分类
    async fieldClassify() {
      const res = await fetchFieldClassify()
      const { data } = res
      this.fieldLists = data.map(item => ({
        id: item.id,
        field: item.name,
        unhover: fieldType[item.name].unhover,
        isHover: false,
        hover: fieldType[item.name].hover,
        total: item.all_count,
        updates: item.seven_count,
        classify: item.items.map(val => ({ label: val.name, value: val.name, id: val.id, count: val.count }))
      }))
      this.loading = false
    },
    // 我的检索式
    async OwnerSearch() {
      const res = await fetchOwnerSearch({
        // userId: this.$store.state.user.userId,
        // userId: localStorage.getItem('userID'),
        is_subscribed: '1',
        t: new Date().getTime()
      })
      const { data } = res
      this.searchFormulas = data.map(item => ({
        ...item,
        labels: item.content.split('\t'),
        updates: item.seven_days_count,
        total: item.all_count
      }))
    },
    // 高级检索式
    async AdvancedSearch() {
      const res = await fetchAdvancedSearch({ search_type: 'advanced_search' })
      const { data } = res
      this.historyLists = data.map(item => ({
        ...item,
        labels: item.content.split('\t')
      }))
    },
    isToggle(key, bool) {
      this.fieldLists[key].isHover = bool
    },
    isMouseOn(key) {
      debounce(this.isToggle(key, true), 10)
    },
    isMouseOut(key) {
      debounce(this.isToggle(key, false), 10)
    },
    toListsPage(item) {
      this.setFlag(true)
      this.$router.push({
        path: 'literature/lists',
        query: { ...item }
      })
    },
    isSingleClick(item) {
      const { field, id } = item
      this.toListsPage({
        field,
        field__id: id
      })
    },
    isVarietyClick(item, field) {
      const { label, id } = item
      this.toListsPage({
        field,
        label,
        disease__id: id
      })
    },
    isFormula(formula) {
      const { labels, search_type, search_content } = formula
      const item = {
        collect: labels.join(','),
        type: 'collect',
        search_type,
        search_content
      }
      this.toListsPage(item)
    },
    async isDelete(id) {
      await deleteFormulas(id)
      Message.success({
        content: '删除成功！',
        duration: 2
      })
      this.OwnerSearch()
    },
    isSearch(val) {
      // 匹配中文逗号 -> 英文
      const reg = /，/ig
      if (reg.test(val)) {
        val = val.replace(reg, ',')
      }
      const item = {
        search: val,
        type: 'normal'
      }
      this.toListsPage(item)
    },
    isAdvance() {
      this.AdvancedSearch()
      this.isModalShow = true
    },
    isHistory(item) {
      const { labels, search_type, search_content } = item
      const vals = setStrToAdvanced(labels)
      const val = {
        advanced: JSON.stringify(vals),
        type: 'advanced',
        search_type,
        search_content
      }
      this.toListsPage(val)
    },
    isSure() {
      const len = this.advancedLists.length
      const lastItem = this.advancedLists[len - 1]
      if (len > 1 && (!lastItem.label || !lastItem.logic || !lastItem.search)) {
        Message.warning({
          content: '请完善搜索条件',
          duration: 2
        })
      } else if (len === 1 && (!lastItem.label || !lastItem.search)) {
        Message.warning({
          content: '请完善搜索条件',
          duration: 2
        })
      } else {
        this.isModalShow = false
        // 搜索后恢复默认
        const item = {
          advanced: JSON.stringify(this.advancedLists),
          type: 'advanced'
        }
        this.toListsPage(item)
        this.advancedLists = AdvancedDefault
      }
    },
    isCancel() {
      this.isModalShow = false
      this.advancedLists = AdvancedDefault
    },
    isPlus() {
      const item = {
        label: '',
        logic: '',
        search: ''
      }
      this.advancedLists.push(item)
    },
    isMinus(key) {
      this.advancedLists.splice(key, 1)
    }
  }
}
</script>

<style lang='less'>
@import url('./index.less');
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    max-height: 620px;
    top: 0;
  }
  .ivu-modal-header-inner {
    font-weight: 700;
    font-size: 20px;
    color: #2D3937;
  }
  .ivu-modal-close {
    .ivu-icon-ios-close {
      font-weight: 700;
      font-size: 36px;
      color: #2D3937;
    }
  }
  .ivu-modal-footer{
    border-top: none;
    margin-top: -12px;
    padding-bottom: 16px;
  }
  .cancle{
    border: none;
  }
}
</style>
